@import '../../../scss/theme-bootstrap';
/* Customer Care Consultants PC */
#LP_DIV_1497359040757 {
  display: none;
}

.device-mobile #livechat .account-livechat-transcripts .cs-formatter-v1 {
  background: none repeat scroll 0 0 #f5f5f6;
  border-bottom: 1px solid #cccccc;
}

.device-mobile #livechat .account-livechat-transcripts .cs-formatter-v1,
.customer-care-our-online-consultants .cs-formatter-v1 {
  border-top: 1px solid #cccccc;
}

.account-livechat-transcripts .cs-formatter-v1 {
  border-bottom: 1px solid #dbdbdb;
}

.customer-care-our-online-consultants .cs-formatter-v1 {
  .customer-care-list {
    .customer-care-list-item {
      margin: 0 auto;
      float: none;
      &.item-1 {
        min-height: 132px;
        padding-top: 15px;
        .bottom {
          top: 94px;
        }
      }
      .customer_chat_now {
        @include swap_direction(margin, 15px 0);
        width: 100%;
        @media #{$medium-up} {
          width: 490px;
        }
        .sticky-chat-button {
          text-align: center;
          div {
            background: transparent !important;
            width: auto !important;
            cursor: pointer;
            img {
              position: relative !important;
            }
          }
        }
      }
      &.item-2 {
        border-#{$ldirection}: none;
        min-height: 180px;
        .bottom {
          top: 144px;
        }
      }
    }
    &.sms-live__chat {
      position: relative;
      h2 {
        color: $color-black;
        margin-bottom: 5px;
        font-size: 24px;
      }
      .item-1 {
        float: #{$ldirection};
        margin-right: 50px;
        width: 48%;
        @include breakpoint(768px) {
          margin-right: 10px;
        }
      }
      .item-2 {
        min-height: 220px;
        margin-top: 15px;
        display: inline-block;
        .sub-item {
          position: absolute;
          bottom: 13%;
          left: 0;
          @include breakpoint(1024px) {
            left: 0;
            bottom: 5%;
          }
          width: 100%;
          .customer_chat_now {
            display: inline-block;
            width: 30%;
            @include breakpoint(768px) {
              width: 18%;
            }
          }
          .messenger {
            display: inline-block;
            @include swap_direction(margin, 5px 18px);
            .chat_mesenger {
              font-family: $base-font-family-roman;
              color: $color-black;
              font-weight: bold;
              border: 1px solid $color-black;
              @include swap_direction(padding, 10px 30px);
              @include breakpoint(768px) {
                @include swap_direction(padding, 10px 20px);
              }
            }
          }
          .or-text {
            font-weight: bold;
          }
          .consultant {
            display: inline-block;
            @include swap_direction(margin, 5px 0 5px 18px);
            font-weight: bold;
          }
        }
        .customer_technical_order {
          position: absolute;
          width: 48%;
        }
      }
    }
  }
}
